.firebaseui-container {
  font: 16px Noto Sans !important;
}

.firebaseui-title {
    font-size: 18px !important;
}


.mdl-button--raised.mdl-button--colored {
    border-radius: 10px;
}
